import styles from './FooterLink.module.css'

export interface IFooterLink {
    link: string;
    text: string;
    currentPage?: boolean;
    itemId?: string;
}

const FooterLink = ({link, text, currentPage=false, itemId=''}: IFooterLink) => {



    const handleClick = () => {
    const onPageItem = document.getElementById(itemId)
    onPageItem?.scrollIntoView({behavior: 'smooth'})
    }

    if(currentPage) {
        return (
            <span className={styles.link} onClick={handleClick}>
                {text}
            </span>
        )
    }

    return (
        <a href={link} target='_blank' rel="noreferrer" className={styles.link}>
            {text}
        </a>
    )
}

export default FooterLink;