export interface IcouncilButton {
    id: number;
    text: string;
}

export const councilButtons = [
    {
        id: 1,
        text: 'Состав'
    },
    {
        id: 2,
        text: 'Статьи'
    },
    {
        id: 3,
        text: 'Законодательство'
    },
    {
        id: 4,
        text: 'Нормативные документы'
    },
]