import {Provider} from 'react-redux'
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './pages/Main/Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import Join from './pages/Join/Join';
import Header from './UI/Header/Header';
import Footer from './UI/Footer/Footer';
import Council from './pages/Council/Council';
import Contacts from './pages/Contacts/Contacts';
import News from './pages/News/News';
import { store } from './store/store';
import NewsItemPage from './pages/NewsItemPage/NewsItemPage';
import Partners from './pages/Partners/Partners';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
  <Provider store={store}>
    <div className='appContainer'>
      <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/join" element={<Join></Join>}></Route>
          <Route path="/council" element={<Council></Council>}></Route>
          <Route path="/partners" element={<Partners></Partners>}></Route>
          <Route path="/contacts" element={<Contacts></Contacts>}></Route>
          <Route path="/news" element={<News></News>}></Route>
          <Route path="/newsItem" element={<NewsItemPage></NewsItemPage>}></Route>
        </Routes>
        <Footer />
    </div>
  </Provider>
  </BrowserRouter>  
);
