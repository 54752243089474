import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"

export interface NewsState {
    id: number;
    attributes: {
      title: string;
      firstText: string;
      firstImage: string;
      secondText: string | null;
      SecondImage: string | null;
      publishedAt: number;
    }
}
  
const initialState: {news: NewsState } = {
    news: {
      id: 0,
      attributes: {
        title: '',
        firstText: '',
        firstImage: '',
        secondText: '',
        SecondImage: '',
        publishedAt: 0
      }
    }
}
  
  export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
      rewriteNews: (state, action: PayloadAction<NewsState>) => {
        state.news = {...action.payload}
      },
    },
  })
  
  export const { rewriteNews } = newsSlice.actions
  
  export const selectNews = (state: RootState) => state.news
  
  export default newsSlice.reducer