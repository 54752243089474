import classNames from "classnames";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import styles from './NewsItemPage.module.css'

const NewsItemPage = () => {
    const post = useSelector((store: RootState) => store.news.news)    
    const navigate = useNavigate()

    useEffect(()=> {
        if(!post.attributes.title) {
            navigate("/news")
        }
    }, [])



    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{post.attributes.title}</h2>
            <div className={styles.content}>
                <img src={post.attributes.firstImage} alt='первое изображение' className={styles.image}></img>
                <p className={styles.text}>
                    {post.attributes.firstText}
                </p>
            </div>
            <div className={styles.content}>
                {post.attributes.secondText ? <p className={classNames([styles.text, styles.secondText])}>{post.attributes.secondText}</p> : null}
                {post.attributes.SecondImage ? <img src={post.attributes.SecondImage} alt='первое изображение' className={styles.image}></img> : null}
            </div>
        </div>
    )
}

export default NewsItemPage;