import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { rewriteNews } from "../../store/slices/newsSlice"
import dateParser from "../../utils/dateParser"
import { INewsItem } from "../NewsItem/NewsItem"
import styles from './NewsPageItem.module.css'

const NewsPageItem = ({ post}: INewsItem) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const background = useState({
        background: `url(${post.attributes.firstImage})`,
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    })

    const handleClick = () => {
        dispatch(rewriteNews(post))
        navigate('/newsItem')
    }

    return (
        <div className={styles.container} onClick={handleClick}>
            <div className={styles.innerContainer} style={background[0]}>
                <span className={styles.date}>{dateParser(post.attributes.publishedAt)}</span>
                <span className={styles.title}>{post.attributes.title}</span>
            </div>
        </div>
    )
}

export default NewsPageItem;