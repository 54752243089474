import Compound from "../components/councilComponents/Compound/Compound";
import Law from "../components/councilComponents/Law/Law";
import Normative from "../components/councilComponents/Normative/Normative";
import Titles from "../components/councilComponents/Titles/Titles";

interface ICouncilSwitch {
    id: number;
}

const CouncilSwitch = ({id}: ICouncilSwitch) => {
    if(id === 1) {
        return <Compound />
    } else if (id === 2) {
        return <Titles />
    } else if (id===3) {
        return <Law />
    } else if (id===4) {
        return <Normative />
    }
    else {
        return <div />
    }
    
}

export default CouncilSwitch;