const Titles = () => {
    return (
        <div>
            <ul>
                <li>
                    <a href='https://www.ruselprom.ru/upload/smi/neftegaz_ru_10.pdf' target='_blank' rel="noreferrer">Закупка малоэффективных двигателей – это экономическое преступление</a>
                    <p>Neftegaz.ru</p>
                </li>
                <li>
                    <a href='https://www.ruselprom.ru/upload/smi/prodolgaya-tradicii-lez.pdf' target='_blank' rel="noreferrer">Продолжая традиции ЛЭЗ</a>
                    <p>Журнал «Газотурбинные технологии» №3</p>
                </li>
                <li>
                    <a href='https://www.ruselprom.ru/upload/smi/sviridov-maksim-podhod-ot-potrebnostey.pdf' target='_blank' rel="noreferrer">Максим Свиридов: подход «от потребностей»</a>
                    <p>Журнал «СФЕРА. Нефть и Газ» </p>
                </li>
                <li>
                    <a href='https://www.ruselprom.ru/upload/smi/luchschee-reshenie-dlya-gornogo-transporta.pdf' target='_blank' rel="noreferrer">Лучшее решение для горного транспорта</a>
                    <p>Журнал «Вести в электроэнергетике» </p>
                </li>
                <li>
                    <a href='https://www.ruselprom.ru/upload/smi/glovatskiy-av-80.pdf' target='_blank' rel="noreferrer">А.В. Гловацкий – про жизнь, профессию, страну и РУСЭЛПРОМ</a>
                </li>
            </ul>
        </div>
    )
}

export default Titles;