import { useEffect, useState } from "react";
import apiClient from "../../apiClient/apiClient";
import PartnersItem, { IPartnersItem } from "../../components/PartnersItem/PartnersItem";
import styles from './Partners.module.css'

const Partners = () => {
    const [partnersArray, setPartnersArray] = useState<IPartnersItem[]>([])
    const [joinButton, setJoinButton] = useState<IPartnersItem>()    

    useEffect(()=> {
        (async ()=> {
            const partners: IPartnersItem[] = (await apiClient.getPartners()).data.data.filter((partner: IPartnersItem) => partner.attributes.title !== 'join')            
            const join: IPartnersItem = (await apiClient.getJoinPartner()).data.data[0]
            setPartnersArray(partners)
            setJoinButton(join)
        })()
        
    },[])

    return (
        <div className={styles.pageContainer}>
            <div className={styles.imageBlock}>
                <img src='./static/pictures/partners/main.png' alt='Новости' className={styles.image}></img>
            </div>
            <div className={styles.container}>
            {partnersArray!.map(partner => <PartnersItem id={partner.id} attributes={partner.attributes} key={partner.id} />)}
            {joinButton ? <PartnersItem id={joinButton.id} attributes={joinButton.attributes}/> : null}
            </div>
        </div>
    )
}

export default Partners;