import styles from './PartnersItem.module.css'

export interface IPartnersItem {
    id: number;
    attributes: {
        img: string;
        link: string;
        title: string;
    }
}

const PartnersItem = ({ id, attributes }: IPartnersItem) => {
    return (
        <a href={attributes.link} target={attributes.title !== 'join' ? '_blank' : '_self'} rel="noreferrer" className={attributes.title !== 'join' ? styles.container : styles.joinContainer}>
            <div className={styles.image}>
                <img src={attributes.img} alt='partner'/>
            </div>
            <span className={attributes.title !== 'join' ? styles.title : styles.displayNone}>{attributes.title}</span>
            <span className={attributes.title !== 'join' ? styles.link : styles.displayNone}>{attributes.link}</span>
        </a>
    )
}

export default PartnersItem