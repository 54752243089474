import axios from "axios";

const apiClient = {
    endpoint: 'https://xn--80ajbanbkglmbgeaxgqki9i7d.xn--p1ai/api/',
    
    get: (url: string) => {
        return axios.get(apiClient.endpoint + url)
    },

    getPosts: async (limit: number): Promise<any> => {
        const resp =  await (await axios.get(apiClient.endpoint + `posts?sort[0]=createdAt%3Adesc&pagination[start]=0&pagination[limit]=${limit}`))
        console.log(resp);
        return resp;
    },

    getThreePosts: async (): Promise<any> => {
        return await axios.get(apiClient.endpoint + 'posts?sort[0]=createdAt%3Adesc&pagination[start]=0&pagination[limit]=3')
    },

    getPartners: async (): Promise<any> => {
        return await axios.get(apiClient.endpoint + 'partners')
    },

    getJoinPartner: async (): Promise<any> => {
        return await axios.get(apiClient.endpoint + 'partners?filters[title][$eq]=join')
    }
}

export default apiClient;