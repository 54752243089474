import { useState } from 'react';
import Titles from '../../components/councilComponents/Titles/Titles';
import { councilButtons, IcouncilButton } from '../../constants/councilButtons';
import CouncilSwitch from '../../utils/councilSwitch';
import styles from './Council.module.css'

const Council = () => {

    const [buttonActive, setbuttonActive] = useState(1);

    const handleButtonClick = (id: number) => {
        setbuttonActive(id)
    }

    return(
        <div className={styles.container}>
            <div className={styles.imageBlock}>
                <img src='./static/pictures/council/main.png' alt='pic' className={styles.image}></img>
            </div>
            <div className={styles.infoBlock}>
                <div className={styles.buttons}>
                    {councilButtons.map((button: IcouncilButton)=>(
                    <button className={buttonActive === button.id ? styles.infoButtonClicked : styles.infoButton} type="button" key={button.id} onClick={() => handleButtonClick(button.id)}>{button.text}</button>
                    ))}
                </div>
                <div>
                 <CouncilSwitch id={buttonActive} />
                </div>
            </div>

        </div>
    )
}

export default Council;