import { NewsState, rewriteNews } from "../../store/slices/newsSlice";
import { useDispatch } from 'react-redux';
import styles from './NewsItem.module.css'
import dateParser from "../../utils/dateParser";
import { useNavigate } from "react-router";

export interface INewsItem {
    post: NewsState;
}

const NewsItem = ({ post }: INewsItem) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()    

    const handleClick = () => {
        dispatch(rewriteNews(post))
        navigate('/newsItem')
    }

    const postFirstText = post.attributes.firstText.length > 80 ? post.attributes.firstText.slice(0, 80).padEnd(83, '.') : post.attributes.firstText

    return (
        <div className={styles.container} onClick={handleClick}>
            <span className={styles.date}>{dateParser(post.attributes.publishedAt)}</span>
            <h4 className={styles.title}>{post.attributes.title}</h4>
            <p className={styles.text}>{postFirstText}</p>
        </div>
    )
}

export default NewsItem;