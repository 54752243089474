import {  useEffect, useState } from "react";
import apiClient from "../../apiClient/apiClient";
import NewsPageItem from "../../components/NewsPageItem/NewsPageItem";
import { NewsState } from "../../store/slices/newsSlice";
import styles from "./News.module.css";
import { useInView } from 'react-intersection-observer'
import { throttle } from 'throttle-typescript'

const News = () => {
  const { ref, inView } = useInView()
  const [news, setNews] = useState<NewsState[]>([])
  const [newsPagination, setNewsPagination] = useState(15)
    
  useEffect(()=> {
    if(inView) {
      throttle(()=> setNewsPagination(newsPagination+15), 100)()
    }
  }, [inView])

  useEffect(()=> {
  (async ()=> {    
        const newsFromServer: NewsState[] = (await apiClient.getPosts(newsPagination)).data.data
      console.log(newsFromServer);

        setNews(newsFromServer)            
    })()
  }, [newsPagination])     

    return (
        <div className={styles.container}>
            <div className={styles.imageBlock}>
                <img src='./static/pictures/news/main.png' alt='Новости' className={styles.image}></img>
            </div>
            <div className={styles.newsBlock}>
                {!news ? 'Loading...' : news.map(element => <NewsPageItem post={element} key={element.id} />)}
            </div>
            <div ref={ref}></div>
        </div>
    )
}

export default News;