import { IFooterLink } from "../components/FooterLink/FooterLink";

const footerLinks: IFooterLink[] = [
    {
        link: '#',
        text: 'Новости'
    },
    {
        link: '#',
        text: 'Участники'
    },
    {
        link: '#',
        text: 'Тех совет'
    },
    {
        link: '#',
        text: 'Вступление'
    },
    {
        link: '#',
        text: 'О нас',
        currentPage: true,
        itemId: 'about'
    },
    {
        link: '#',
        text: 'Продукты',
        currentPage: true,
        itemId: 'products'
    }
];

export default footerLinks;