import { IProductsItem } from "../components/ProductsItem/ProductsItem";

const arrayOfProducts: IProductsItem[] = [
    {
        id: 'first',
        img: './static/pictures/productItems/firstEngine.png',
        text: 'Высоковольтные электродвигатели',
        link: 'https://www.ruselprom.ru'
    },
    {
        id: 'second',
        img: './static/pictures/productItems/elevator.png',
        text: 'Лифты',
        link: 'https://www.liftmach.by'
    },
    {
        id: 'third',
        img: './static/pictures/productItems/car.png',
        text: 'Электротележки',
        link: 'https://segz.ru'
    },
    {
        id: 'fourth',
        img: './static/pictures/productItems/atlant.png',
        text: 'Бытовая техника',
        link: 'https://www.atlant.by'
    },
    {
        id: 'fifth',
        img: './static/pictures/productItems/secondEngine.png',
        text: 'Электродвигатели специального назначения',
        link: 'http://ural-motor.ru'
    },
    {
        id: 'sixth',
        img: './static/pictures/productItems/pump.png',
        text: 'Электронасосы',
        link: 'https://rotor.brest.by'
    },
    {
        id: 'seventh',
        img: './static/pictures/productItems/thirdEngine.png',
        text: 'Электродвигатели общепромышленные',
        link: 'https://mez-electro.ru'
    },
    {
        id: 'eight',
        img: './static/pictures/productItems/turbina.png',
        text: 'Приводные турбины',
        link: 'https://power-m.ru'
    },
]

export default arrayOfProducts;