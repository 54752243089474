import styles from './JoinText.module.css'

const JoinText = () => {
    return (
        <p className={styles.text}>
            Членами отраслевого союза «Электромашиностроение» могут быть юридические лица, разделяющие цели и задачи отраслевого Союза, установленные действующим законодательством Российской Федерации и Уставом отраслевого союза «Электромашиностроение». 
            Прием в отраслевой союз «Электромашиностроение» осуществляется согласно Положению о членстве в отраслевом союзе, утвержденному Общим собранием членов отраслевого союза «Электромашиностроение». Положение о членстве содержит условия членства в отраслевом союзе «Электромашиностроение», в том числе: условия и порядок приема в члены отраслевого союза «Электромашиностроение», права и обязанности членов отраслевого союза «Электромашиностроение» порядок прекращения членства в отраслевом союзе «Электромашиностроение». 
        </p>
    )
}

export default JoinText;