const Normative = () => {
    return (
        <div>
            <ul>
                <li style={{marginBottom: '30px'}}>
                    <div>Технический регламент Таможенного союза 004/2011 «О безопасности низковольтного оборудования», </div>
                    <a href='https://docs.cntd.ru/document/902299536' target='_blank' rel="noreferrer">https://docs.cntd.ru/document/902299536</a>
                </li>
                <li>
                    <div>ТЕХНИЧЕСКИЙ РЕГЛАМЕНТ ТАМОЖЕННОГО СОЮЗА ТР ТС 020/2011</div> 
                    <div>Электромагнитная совместимость технических средств</div>
                    <a href='https://docs.cntd.ru/document/902320551' target='_blank' rel="noreferrer">https://docs.cntd.ru/document/902320551</a>
                </li>
                <li>
                    <div>Технический регламент Таможенного союза 012/2011 «О безопасности оборудования для работы во взрывоопасных средах»</div> 
                    <a href='https://www.reglament.by/wp-content/uploads/docs/tr/tr-ts-012-2011-o-bezopasnosti-' target='_blank' rel="noreferrer">https://www.reglament.by/wp-content/uploads/docs/tr/tr-ts-012-2011-o-bezopasnosti-</a>
                    <a href='https://www.reglament.by/normative-base/tr/tr-ts-012/ ' target='_blank' rel="noreferrer">https://www.reglament.by/normative-base/tr/tr-ts-012/ </a>
                </li>
            </ul>
        </div>
    )
}

export default Normative;