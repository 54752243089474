const Law = () => {
    return(
        <div>
            <p>Договор о Евразийском экономическом союзе от 29 мая 2014 г.,</p>
            <p>Указ Президента Республики Беларусь от 05.04.2016 N 124 "О государственном регулировании внешнеторговой деятельности".</p>
            <p>Федеральный закон РФ «О техническом регулировании» от 27.12.2002 года № 184-ФЗ.</p>
            <ul>
            <li>
                    <div>Договор о ..</div> 
                    <a href='http://www.consultant.ru/document/cons_doc_LAW_163855/' target='_blank' rel="noreferrer">http://www.consultant.ru/document/cons_doc_LAW_163855/</a>
            </li>
            <li>
                    <div>Указ президента 124</div> 
                    <a href='https://kodeksy-by.com/norm_akt/source-%D0%9F%D1%80%D0%B5%D0%B7%D0%B8%D0%B4%D0%B5%D0%BD%D1%82%20%D0%A0%D0%91/type-%D0%A3%D0%BA%D0%B0%D0%B7/124-05.04.2016.htm' target='_blank' rel="noreferrer">https://kodeksy-by.com/norm_akt/source-%D0%9F%D1%80%D0%B5%D0%B7%D0%B8%D0%B4%D0%B5%D0%BD%D1%82%20%D0%A0%D0%91/type-%D0%A3%D0%BA%D0%B0%D0%B7/124-05.04.2016.htm</a>
                    <a href='http://www.consultant.ru/document/cons_doc_LAW_40241/ ' target='_blank' rel="noreferrer">http://www.consultant.ru/document/cons_doc_LAW_40241/ </a>
            </li>
            </ul>
        </div>
    )
}

export default Law;