interface ImainPageItems {
    id: number;
    img: string;
    title: string;
    text: string;
} 

const mainPageItems: ImainPageItems[] = [
    {
        id: 0,
        img: './static/pictures/mainItems/organisation.png',
        title: 'Движущая сила',
        text: '4 из 5 производимых электродвигателей в Российской Федерации и Республике Беларусь производится предприятиями отраслевого союза'
    },
    {
        id: 1,
        img: './static/pictures/mainItems/money.png',
        title: 'Инновации',
        text: 'Инновационное обновление ассортимента с учетом современных вызовов'
    },
    {
        id: 2,
        img: './static/pictures/mainItems/sertificate.png',
        title: 'Взаимодействие',
        text: 'Минпромторг России, Министерство промышленности Республики Беларусь, органы ЕЭК'
    },
    {
        id: 3,
        img: './static/pictures/mainItems/sertificate2.png',
        title: 'Поддержка',
        text: 'Стабильное предприятие-стабильная отрасль- стабильная экономика-наше благосостояние!'
    },
    {
        id: 4,
        img: './static/pictures/mainItems/import.png',
        title: 'Импортозамещение',
        text: 'Обеспечиваем экономическую безопасность'
    },
    {
        id: 5,
        img: './static/pictures/mainItems/science.png',
        title: 'Наука',
        text: 'Синергия науки и производства - путь в будущее!'
    },
]

export { mainPageItems };