import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux'
import MainPageItem from '../../components/MainPageItem/MainPageItem';
import NewsItem from '../../components/NewsItem/NewsItem';
import ProductsItem from '../../components/ProductsItem/ProductsItem';
import { mainPageItems } from '../../constants/mainPageItems';
import arrayOfProducts from '../../constants/productItems';
import Scrollable from '../../HOC/Scrollable';
import { NewsState } from '../../store/slices/newsSlice';
import CarouselComponent from '../../UI/Carousel/CarouselComponent';
import styles from './Main.module.css';
import apiClient from '../../apiClient/apiClient';
const uniqid = require('uniqid');


function App() {
  const [news, setNews] = useState<NewsState[]>([])

  useEffect(()=> {
    (async ()=> {
      const newsFromServer: NewsState[] = (await apiClient.getThreePosts()).data.data
      setNews(newsFromServer)   
    })()
  }, [])

  return (
    <div className={styles.mainPageContainer}>
      <div className={styles.container}>
        <img width="100%"  src="./static/pictures/mainBanner.png" className={styles.video}/>
      </div>
      <div className={styles.itemsContainer} id='about'>
        {mainPageItems.map(item => <MainPageItem img={item.img} title={item.title} text={item.text} key={uniqid()} id={mainPageItems.indexOf(item)} />)}
      </div>
      <CarouselComponent />
      <div className={styles.newsBlock}>
        <h3 className={styles.newsTitle}>Новости компании</h3>
          <div className={'sliderContainer'}>
            <Scrollable class='sliderSlides'>
            {!news ? <div>'Loading...'</div> : news.map(element => <NewsItem post={element} key={element.id} />)}
            </Scrollable>
          </div>
          <NavLink to="/news" className={styles.newsLink}>Все новости</NavLink>
      </div>
      <div className={styles.productsBlock} id='products'>
        <h3 className={styles.productsTitle}>Продукты наших участников</h3>
        <div className={styles.productItemsContainer}>
          {arrayOfProducts.map(product => <ProductsItem text={product.text} img={product.img} link={product.link} key={uniqid()} id={product.id} />)}
        </div>
      </div>
    </div>
  );
}

export default App;
